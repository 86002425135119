import * as React from 'react'
import { Link } from 'gatsby'

import logo_light from '../images/logo_light.png'
import logo_dark from '../images/logo_dark.png'
import Navigation from './Navigation'
import { logoTitle } from '../../config/website'

export class Header extends React.Component {
  constructor(props) {
    super(props)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.state = {
      menuOpen: false,
    }
  }

  toggleMenu() {
    const currentState = this.state.menuOpen;
    this.setState({ menuOpen: !currentState });
  }

  render() {
    const { children, hideBorderTop, useDarkHeader } = this.props

    return (
      <>
        <div className="banner-archive text-sm md:text-base">
          You are on an outdated page that we keep for archiving purposes. Visit our new website by clicking <a href="https://www.sdia.io/">here</a>.
        </div>
        <header 
          className={`
            c-header text-gray-900
            ${useDarkHeader ? '' : 'md:text-white'} 
            w-full flex flex-col fixed md:fixed bg-white md:bg-transparent pin-r pin-l z-50
            top-[80px] md:top-[44px]
          `}
        >
          <div className={`flex flex-col md:flex-row w-full justify-between items-center px-4 md:px-6 py-3 shadow md:shadow-none${hideBorderTop ? '' : ' border-t-4 border-primary-900'}`}>
            <div className="w-full md:w-auto self-start md:self-center flex flex-row flex-no-wrap justify-between items-center">
              <Link to="/" className="flex flex-row items-center">
                <img src={logo_light} className={`hidden ${useDarkHeader ? 'md:hidden' : 'md:inline'} w-20 mr-3`} alt={logoTitle} />
                <img src={logo_dark} className={`inline ${useDarkHeader ? '' : 'md:hidden'} w-20 mr-3`} alt={logoTitle} />
                <span className="uppercase inline-block w-40 text-xs font-light leading-4 md:text-xxs md:leading-relaxed md:mt-4 md:w-28 lg:text-xs lg:w-40 lg:leading-4 lg:mt-2">
                  {logoTitle}
                </span>
              </Link>

              <button 
                className={`
                  c-navigation__hamburger block md:hidden focus:outline-none
                  ${this.state.menuOpen ? 'c-navigation__hamburger--open' : null}
                `} 
                type="button"
                onClick={this.toggleMenu}
              >
                <span className="c-navigation__hamburger-top-bun"></span>
                <span className="c-navigation__hamburger-bottom-bun"></span>
              </button>
            </div>

            <Navigation
              useDarkHeader={useDarkHeader}
              className={this.state.menuOpen ? 'flex' : 'hidden'}
            />
          </div>
          {children}
        </header>
      </>
    )
  }
}
