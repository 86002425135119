import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import { HubspotFormBuilder } from '../components/forms/HubspotFormBuilder'

const LargeNewsletterForm = ({ slice }) => {
  return (
    <section className="mt-36 sm:mt-[120px] bg-primary-900 py-12 md:py-24">
      <div className="container-medium">
        <h2 className="text-white mb-4 text-2xl">{RichText.asText(slice.primary.headline.richText)}</h2>
        <HubspotFormBuilder id={slice.primary.hubspot_form_id} themeLight large hideLabels />
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyLargeNewsletterForm on PrismicHomepageDataBodyLargeNewsletterForm {
    primary {
      headline {
        richText
      }
      hubspot_form_id
    }
  }

  fragment LandingPageDataBodyLargeNewsletterForm on PrismicLandingPageDataBodyLargeNewsletterForm {
    primary {
      headline {
        richText
      }
      hubspot_form_id
    }
  }

  fragment PageDataBodyLargeNewsletterForm on PrismicPageDataBodyLargeNewsletterForm {
    primary {
      headline {
        richText
      }
      hubspot_form_id
    }
  }

  fragment SteeringGroupDataBodyLargeNewsletterForm on PrismicSteeringGroupDataBodyLargeNewsletterForm {
    primary {
      headline {
        richText
      }
      hubspot_form_id
    }
  }

  fragment ResourceDataBodyLargeNewsletterForm on PrismicResourceDataBodyLargeNewsletterForm {
    primary {
      headline {
        richText
      }
      hubspot_form_id
    }
  }

  fragment DictionaryEntryDataBodyLargeNewsletterForm on PrismicDictionaryEntryDataBodyLargeNewsletterForm {
    primary {
      headline {
        richText
      }
      hubspot_form_id
    }
  }
`

export default LargeNewsletterForm
