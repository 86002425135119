import * as React from 'react'
import { RichText } from 'prismic-reactjs'
import {
  format,
} from 'date-fns'
import BackButton from './BackButton'
import EventPresence from './EventPresence'
import AddToCalendar from './AddToCalendar'
import { linkResolver } from '../utils/LinkResolver'
import LinkButton from './LinkButton'
import ShareExternalLink1 from '../images/icons/streamline-icon-share-external-link-1.svg'
import EventTicketInfo from './EventTicketInfo'
import EventLocationInfo from './EventLocationInfo'
import EventSpeakers from './EventSpeakers'
import EventType from './EventType'
import EventSessions from './EventSessions'

const EventDetail = ({ 
  description,
  start_date_obj,
  end_date_obj,
  free_for_members,
  location,
  image,
  main_link,
  main_link_label,
  presence,
  title,
  type,
  tickets_link_label,
  tickets_link,
  ticket_information,
  ticket_costs,
  sessions,
  requires_tickets,
  recordings,
  press_coverage,
  presentations,
  upcoming_event,
  speakers,
  timezone,
  stream_link,
}) => {
  
  return (
    <section className={`container-medium mt-36 sm:mt-32`}>
      <BackButton>Back to overview</BackButton>

      <div className="flex items-center text-gray-600 font-display text-lg mt-8">
        {requires_tickets ? 
          <p className="">
            {RichText.asText(ticket_costs.richText)}
          </p> : null}
        {!upcoming_event ? <p className={`${requires_tickets ? 'ml-4 border-l-2 pl-4 border-gray-300' : ''}`}>
          {format(start_date_obj, 'do MMMM yyyy HH:mm')} ({timezone || 'Europe/Amsterdam'})
        </p> : null}

        <EventPresence className={`${!upcoming_event ? 'ml-4 pl-5 border-l-2 border-gray-300' : ''}` } presence={presence} />
      </div>

      <h1 className="text-4-5xl mt-2 leading-snug">
        {upcoming_event ? 'Upcoming: ' : null}
        {RichText.asText(title.richText)}
      </h1>

      <EventType free_for_members={free_for_members} type={type} className="mt-2" />

      <div className="prose prose-xl font-display mt-2">
        <RichText render={description.richText} linkResolver={linkResolver} />
      </div>

      {!upcoming_event ? <div className="mt-4">
        <AddToCalendar 
          timezone={timezone}
          start_date={start_date_obj} 
          end_date={end_date_obj} 
          title={RichText.asText(title.richText)}
          description={description ? RichText.asText(description.richText) : null}
          location={location ? RichText.asText(location.richText) : null}
          main_link={main_link ? main_link : null}
          main_link_label={main_link_label}
          stream_link={stream_link}
        />
      </div> : null}

      {main_link && main_link_label ? 
        <LinkButton link={main_link} className="mt-4" isWarning>
          {main_link_label}
          <ShareExternalLink1 className="stroke-current fill-current w-4 h-4 ml-2" />
        </LinkButton> : null}

      {requires_tickets ? 
        <EventTicketInfo 
          className="mt-8 lg:mt-12"
          costs={ticket_costs}
          link_label={tickets_link_label} 
          link={tickets_link} 
          information={ticket_information} 
        /> : null}

      {speakers && speakers.length > 0 ? 
        <EventSpeakers speakers={speakers} className="mt-8 lg:mt-12" /> 
        : null}

      {location ? 
        <EventLocationInfo location={location} presence={presence} className="mt-8 lg:mt-12" /> 
        : null}

      {sessions && sessions.length > 0 ? 
        <EventSessions sessions={sessions} className="mt-8 lg:mt-12" /> 
        : null}
    </section>
  )
}

export default EventDetail


// sessions {
//   hosted_by {
//     richText
//   }
//   hosted_by_the_sdia
//   session_description {
//     richText
//   }
//   session_link {
//     url
//     uid
//     type
//     target
//     link_type
//   }
//   session_link_label
//   session_title {
//     richText
//   }
//   team_member {
//     document {
//       ... on PrismicTeamMember {
//         id
//         data {
//           full_name {
//             richText
//           }
//           linkedin_profile {
//             url
//             target
//             type
//             uid
//             link_type
//           }
//           photo {
//             gatsbyImageData(width: 200)
//           }
//           title {
//             richText
//           }
//         }
//       }
//     }
//   }
// }
// requires_tickets
// recordings {
//   # recording_embed {
//   #   author_name
//   #   author_url
//   #   embed_url
//   #   height
//   #   html
//   #   id
//   #   prismicId
//   #   provider_name
//   #   provider_url
//   #   thumbnail_height
//   #   thumbnail_url
//   #   thumbnail_width
//   #   title
//   #   type
//   #   width
//   # }
//   recording_preview {
//     url
//     gatsbyImageData(width: 200)
//   }
// }
// press_coverage {
//   coverage_title {
//     richText
//   }
//   coverage_preview {
//     gatsbyImageData(width: 200)
//     alt
//   }
//   coverage_media {
//     richText
//   }
//   coverage_link {
//     url
//     uid
//     type
//     target
//     link_type
//   }
//   coverage_link_label
//   coverage_excerpt {
//     richText
//   }
// }
// presentations {
//   presentation_preview {
//     gatsbyImageData(width: 200)
//     alt
//   }
//   # presentation_embed {
//   #   author_name
//   #   author_url
//   #   embed_url
//   #   height
//   #   html
//   #   id
//   #   thumbnail_width
//   #   thumbnail_url
//   #   provider_url
//   #   thumbnail_height
//   #   provider_name
//   #   prismicId
//   #   title
//   #   type
//   #   width
//   #   version
//   # }
//   presentation_document {
//     url
//     uid
//     type
//     target
//     link_type
//   }
// }
