import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { linkResolver } from '../utils/LinkResolver.js'
import LinkButton from '../components/LinkButton.js'

const HeroWithFeatures = ({ slice }) => {
  const background = getImage(slice.primary.background_image)

  return (
    <section className="grid">
      <GatsbyImage
        style={{
          gridArea: "1/1",
          maxHeight: 740,
        }}
        layout="fullWidth"
        alt=""
        image={background}
      />
      <div 
        className="grid py-[200px] lg:py-0 lg:-mt-24 relative place-items-center"
        style={{
          gridArea: "1 / 1 / auto / auto",
        }}
      >
        {/* Any content here will be centered in the component */}
        <div className="container-medium">
          <h2 className="text-white leading-10">
            {RichText.asText(slice?.primary?.title?.richText)}
          </h2>
          <div className="c-divider c-divider--white mt-4" />
          <div className="text-xl font-light font-display text-white mt-6 leading-relaxed">
            <RichText
                render={slice.primary.description.richText}
                linkResolver={linkResolver}
            />
          </div>
          {!!slice?.primary?.call_to_action && !!slice?.primary?.link?.url && (
            <LinkButton
              link={slice.primary.link}
              isWarning
              label={slice.primary.call_to_action}
              className="mt-6"
            />
          )}
        </div>
      </div>

      <div className="container o-stage-overlapper">
        <div className="grid sm:grid-cols-3 gap-4">
          {Array.isArray(slice?.items) && !!slice.items.length && slice.items.map((item, index) => {
            const icon = getImage(item.icon)

            return (
              <div
                className="bg-white rounded-md shadow text-center p-6"
                key={`hero-item-${index}`}
              >
                <GatsbyImage
                  alt={item.icon?.alt}
                  image={icon}
                />
                <h3 className="mt-4 font-normal text-xl">
                  {RichText.asText(item.headline?.richText)}
                </h3>
                <div className="mt-4 text-center text-gray-600 text-lg font-light leading-relaxed">
                  <RichText
                    render={item.description?.richText}
                    linkResolver={linkResolver}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyHeroWithFeatures on PrismicPageDataBodyHeroWithFeatures {
    id
    items {
      description {
        richText
      }
      headline {
        richText
      }
      icon {
        alt
        gatsbyImageData(width: 80)
      }
    }
    slice_label
    slice_type
    primary {
      background_image {
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          imgixParams: {
            blendColor: "2A6049",
            blendAlpha: 45,
            blendMode: "normal",
            fit: "crop",
            crop: "top",
            maxH: 740
          }
        )
      }
      call_to_action {
        richText
      }
      description {
        richText
      }
      link {
        url
        target
        type
        uid
        slug
        link_type
      }
      title {
        richText
      }
    }
  }

  fragment LandingPageDataBodyHeroWithFeatures on PrismicLandingPageDataBodyHeroWithFeatures {
    id
    items {
      description {
        richText
      }
      headline {
        richText
      }
      icon {
        alt
        gatsbyImageData(width: 80)
      }
    }
    slice_label
    slice_type
    primary {
      background_image {
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          imgixParams: {
            blendColor: "2A6049",
            blendAlpha: 45,
            blendMode: "normal",
            fit: "crop",
            crop: "top",
            maxH: 740
          }
        )
      }
      call_to_action {
        richText
      }
      description {
        richText
      }
      link {
        url
        target
        type
        uid
        slug
        link_type
      }
      title {
        richText
      }
    }
  }

  fragment HomepageDataBodyHeroWithFeatures on PrismicHomepageDataBodyHeroWithFeatures {
    id
    items {
      description {
        richText
      }
      headline {
        richText
      }
      icon {
        alt
        gatsbyImageData(width: 80)
      }
    }
    slice_label
    slice_type
    primary {
      background_image {
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          imgixParams: {
            blendColor: "2A6049",
            blendAlpha: 45,
            blendMode: "normal",
            fit: "crop",
            crop: "top",
            maxH: 740
          }
        )
      }
      call_to_action {
        richText
      }
      description {
        richText
      }
      link {
        url
        target
        type
        uid
        slug
        link_type
      }
      title {
        richText
      }
    }
  }

  fragment MembershipDataBodyHeroWithFeatures on PrismicMembershipDataBodyHeroWithFeatures {
    id
    items {
      description {
        richText
      }
      headline {
        richText
      }
      icon {
        alt
        gatsbyImageData(width: 80)
      }
    }
    slice_label
    slice_type
    primary {
      background_image {
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          imgixParams: {
            blendColor: "2A6049",
            blendAlpha: 45,
            blendMode: "normal",
            fit: "crop",
            crop: "top",
            maxH: 740
          }
        )
      }
      call_to_action {
        richText
      }
      description {
        richText
      }
      link {
        url
        target
        type
        uid
        slug
        link_type
      }
      title {
        richText
      }
    }
  }
`

export default HeroWithFeatures
